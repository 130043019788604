import React, { useState, useEffect, useRef } from 'react';
import PixelfedPost from './PixelfedPost'

export default function PixelfedPostsGrid(props) {
  let post_components = []
  for (const post of props.posts){
    const post_data = post.post_data
    const key = "" + post_data.instance_hostname + "." + post_data.id
    post_components.push(
      <PixelfedPost key={key} width="22rem" post={post} />
    )
  }

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: "repeat(auto-fill, 22rem)",
      //gridTemplateRows: "repeat(auto-fill, 10rem)",
      gridGap: "1rem",
      justifyContent: "center",
      backgroundColor: 'white',
      //paddingTop: "1rem",
      paddingBottom: "2rem",
    }}>
      { post_components }
    </div>
  )
}

