import React, { useState, useEffect, useRef } from 'react';
// import MastodoniaHeader from './MastodoniaHeader'
// import MastodoniaFooter from './MastodoniaFooter'
import RecentTootsContainer from './RecentTootsContainer'
// import MastodoniaHeader  from './MastodoniaHeader'
// import MastodoniaFooter  from './MastodoniaFooter'
import PixelfedHeader from './PixelfedHeader'
import PixelfedFooter from './PixelfedFooter'
import RecentPixelfedPostsContainer from './RecentPixelfedPostsContainer'

export default function AllPixelfedPostsPage() {
  return (
    <React.Fragment>
      <PixelfedHeader/>
      <RecentPixelfedPostsContainer/>
      <PixelfedFooter/>
    </React.Fragment>
  )
}


