import React from 'react';
import ReactDOM from 'react-dom';
import AllTootsPage from './AllTootsPage'
import AllPixelfedPostsPage from './AllPixelfedPostsPage'

function renderRoot() {
	let spa_app = process.env.REACT_APP_SPA_APP

	if ( spa_app === "pixelfed") {
		console.log("Rendering Pixelfed.club")
		ReactDOM.render(<AllPixelfedPostsPage />, document.getElementById('root'));
	} else {
		ReactDOM.render(<AllTootsPage />, document.getElementById('root'));
	}
	
}

renderRoot()



// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
