import React, { useState, useEffect, useRef } from 'react';

export default function PixelfedPost(props) {
  let post = props.post
  const post_data = post.post_data


  // let username = props.username
  const username = "@" + post_data.account.username + "@" + post_data.instance_hostname

  // let avatar_img = props.avatar_img
  let avatar_url = ''
  const avatar_cdn = post_data.account.avatar_static_cdn
  if ( avatar_cdn ) {
    avatar_url = avatar_cdn.cdn_url
  }

  //let post_img = props.post_img
  let media_attachment_url = null
  //let css_url = ''
  if ( post_data.media_attachments_cdn ) {
      media_attachment_url = post_data.media_attachments_cdn[0].cdn_url
      //css_url = 'url("' + media_attachment_url + '")'
  }


  //let post_url = props.post_url
  const post_url = post_data.url

  /*
  <div style={{
          //backgroundImage: 'url("https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/1c67b5b0d8047f891737949efa5bfbc7dc006397/fdtTPioFKH8yzfIAPZ2GcMh4qIrm3XTrI3Cc3RT3_thumb.jpeg")',
          backgroundImage: 'url("' + media_attachment_url + '")',
          backgroundSize: "cover",
          backgroundRepeat:   "no-repeat",
          backgroundPosition: "center center",
          flexGrow: 1,
      }}></div>
  */
  

  // className="card"
  // avatar: https://pixelfed.social/storage/avatars/000/000/010/558/qiS6HPJKx2DS2RZjKWsS_avatar.jpeg
  // image: https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/1c67b5b0d8047f891737949efa5bfbc7dc006397/fdtTPioFKH8yzfIAPZ2GcMh4qIrm3XTrI3Cc3RT3_thumb.jpeg
  return (
    <div style={{
      width: '22rem',
      //minWidth: "0",
      //maxWidth: "22rem",
      height: "28rem",
      margin: "0.5rem",
      //flexShrink: "0",
      //padding: '0.5rem',
      fontSize: '0.8rem',
      //overflow: 'hidden',
      fontFamily: 'Montserrat',
      fontWeight: "bold",
      borderWidth: '2px',
      borderColor: "#dfdfdf",
      borderStyle: "solid",
      borderRadius: "0.25rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    }}>
      <div style={{
        padding: "0.5rem",
        borderBottom: " 2px solid #dfdfdf"
      }}>
        <a href={post_url} target="_blank" rel="noopener noreferrer" >
          <img style={{
            width: '1.5rem',
            height: '1.5rem',
            verticalAlign: "middle",
            display: 'inline',
            border: "1px solid gray",
            backgroundColor: 'gray',
            marginRight: '0.2rem',
          }}
          src={avatar_url}
          />
        </a>
        <a style={{ paddingLeft: "0.5rem" }}
        href={post_url} className="text-secondary" target="_blank" rel="noopener noreferrer" >
          {username}
        </a>
      </div>
      <a style={{
          //backgroundImage: 'url("https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/1c67b5b0d8047f891737949efa5bfbc7dc006397/fdtTPioFKH8yzfIAPZ2GcMh4qIrm3XTrI3Cc3RT3_thumb.jpeg")',
          backgroundImage: 'url("' + media_attachment_url + '")',
          backgroundSize: "cover",
          backgroundRepeat:   "no-repeat",
          backgroundPosition: "center center",
          flexGrow: 1,
      }}

      href={post_url}
      target="_blank" rel="noopener noreferrer" 
      ></a>
    </div>
  )
}

