import React, { useState, useEffect, useRef } from 'react';
//import TootGrid from './TootGrid'
import LoadMore from './LoadMore'
import PixelfedPost from './PixelfedPost'
import PixelfedPostsGrid from './PixelfedPostsGrid'


async function getRecentPixelfedPosts() {
    const api_uri = process.env.REACT_APP_API_URI
    const api_limit = process.env.REACT_APP_API_LIMIT
    const api_url = api_uri + '/api/v1/recent_pixelfed_posts?use_cache=1&limit=' + api_limit

    let response = null
    response = await fetch(
      api_url,
      {
        method: "GET",
        mode: "cors",
        credentials: "include",
      }
    )

    if (!response.ok) {
      throw new Error("API response status: " + response.status)
    }

    const json_data = await response.json()
    return json_data
}

async function getPixelfedPostsRange(start_id) {
  const api_uri = process.env.REACT_APP_API_URI
  const api_limit = process.env.REACT_APP_API_LIMIT
  const api_url = api_uri + '/api/v1/pixelfed_posts?limit=' + api_limit + '&start_id=' + start_id

  let response = null
  response = await fetch(
    api_url,
    {
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  )

  if (!response.ok) {
    throw new Error("API response status: " + response.status)
  }

  const json_data = await response.json()
  return json_data

}


export default function RecentPixelfedPostsContainer() {
  const [data_status, setDataStatus] = useState('loading')
  const [data, setData] = useState([])
  const [last_auto_id, setLastAutoId] = useState(null)
  const [counter, setCounter] = useState(0)
  const [load_more, setLoadMore] = useState(true)

  async function LoadPosts() {
    let response = null
    try {
      response = await getRecentPixelfedPosts()
    } catch(e) {
      console.log(e)
      setDataStatus('error')
      return
    }

    let new_posts = response.concat(data)
    if (new_posts.length) {
      setLastAutoId(new_posts[new_posts.length - 1].auto_id)
    }
    
    setData(new_posts)
    setDataStatus('ready')
  }

  async function LoadMorePosts() {
    if ( counter >= 10 ) {
      setLoadMore(false)
      return
    }
    setDataStatus('loading')

    let response = null
    try {
      response = await getPixelfedPostsRange( last_auto_id - 1 )
    } catch(e) {
      console.log(e)
      setDataStatus('error')
      return
    }

    if ( response.length == 0 ) {
      setLoadMore(false)
      return
    }

    let new_posts = data.concat(response)
    if (new_posts) {
      setLastAutoId(new_posts[new_posts.length - 1].auto_id)
    }
    
    setData(new_posts)
    setCounter( counter => counter + 1 )
    setDataStatus('ready')
  }

  useEffect(() => {
    LoadPosts()
  }, [])

  
  return (
    <React.Fragment>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "1rem",
        fontFamily: 'Montserrat',
        fontSize: '0.9rem',
        backgroundColor: "white",
      }}>
        <div style={{ maxWidth: '40rem', minWidth: '0'}}>
          Recent posts from popular pixelfed instances
        </div>
      </div>
      <PixelfedPostsGrid posts={data}/>
      <LoadMore
        onLoadMore={LoadMorePosts} data_status={data_status}
        error={null} enabled={load_more}
      />
    </React.Fragment>
  )
  


  
  /*
  return (
    <div style={{
      backgroundColor: "white",
      //padding: "2rem",
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'center',
      // alignItems: 'flex-start',
      // flexWrap: 'wrap',
      display: 'grid',
      gridTemplateColumns: "repeat(auto-fill, 22rem)",
      //gridTemplateRows: "repeat(auto-fill, 10rem)",
      gridGap: "1rem",
      justifyContent: "center",
      backgroundColor: 'white',
      //paddingTop: "1rem",
      paddingBottom: "2rem",
    }}>
      <PixelfedPost
        post_url="https://pixelfed.social/p/Hatebunny/148965602319536128"
        username="@hatebunny@pixelfed.social"
        avatar_img="https://pixelfed.social/storage/avatars/000/000/010/558/qiS6HPJKx2DS2RZjKWsS_avatar.jpeg"
        post_img="https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/d047a6bdcde09412640f504c99d1444e56c56a86/QTVeEGAqiDYLnDQLIGas3xHqV0DpVi0necN2nUuj_thumb.jpeg"
      />
      <PixelfedPost
        post_url="https://pixelfed.social/p/KelsonV/148958918339596288"
        username="@kelsonv@pixelfed.social"
        avatar_img="https://pixelfed.social/storage/avatars/000/000/000/342/avatar.jpeg?v=d4735e3a265e16eee03f59718b9b5d03019c07d8b6c51f90da3a666eec13ab35"
        post_img="https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/1c67b5b0d8047f891737949efa5bfbc7dc006397/fdtTPioFKH8yzfIAPZ2GcMh4qIrm3XTrI3Cc3RT3_thumb.jpeg"
      />
      <PixelfedPost
        post_url="https://pixelfed.social/p/KelsonV/148958206557818880"
        username="@kelsonv@pixelfed.social"
        avatar_img="https://pixelfed.social/storage/avatars/000/000/000/342/avatar.jpeg?v=d4735e3a265e16eee03f59718b9b5d03019c07d8b6c51f90da3a666eec13ab35"
        post_img="https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/1c67b5b0d8047f891737949efa5bfbc7dc006397/7c0CYudQ1aAdLl3Z4moo5yqDhIzgOym4Vfx1WUnv_thumb.jpeg"
      />
      <PixelfedPost
        post_url="https://pixelfed.social/p/stinkflower/148926070257750016"
        username="@stinkflower@pixelfed.social"
        avatar_img="https://pixelfed.social/storage/avatars/006/067/275/926/877/388/8/vi3UXKuNATGc6F1qczDZ_avatar.jpeg?v=4b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a"
        post_img="https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/a8c61d51e6ea64d2ab494e3644e1e93a9c70904b/U6Gn4l1Dqz0lwLiMT0XVWswtalEOzSlVCQ7EqF6v_thumb.jpeg"
      />
      <PixelfedPost
        post_url="https://pixelfed.social/p/pitbuster/148919972368879616"
        username="@pitbuster@pixelfed.social"
        avatar_img="https://pixelfed.social/storage/avatars/009/139/209/060/444/160/0/APZw7ilmqjOn8ItWY6uU_avatar.jpeg?v=4b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a"
        post_img="https://pixelfed.social/storage/m/113a3e2124a33b1f5511e531953f5ee48456e0c7/aec00bd17b3c701414d0ee2651072c12ebdd1a14/0Ow4iEs8OAkVJXnM2K3c11Xidsq5ZeDjP188ZRA7_thumb.jpeg"
      />
    </div>
  )
  */

}
